import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import styles from 'styles/components/BottomButtons.module.scss';

const BottomButtons = ({
  text,
  textContained,
  handleClose,
  handleClick,
  fixed,
  disabled,
  outlined,
  newStyles,
  isLoading,
  textForLoading,
  sticky,
}) => (
  <div
    className={clsx(
      styles.buttonsContainer,
      { [styles.fixed]: fixed },
      { [styles.sticky]: sticky },
      { [newStyles.centerButtons]: !isEmpty(newStyles) }
    )}
  >
    {!isLoading && (
      <Button onClick={handleClose} variant={outlined ? 'contained' : 'text'} color="primary">
        {text}
      </Button>
    )}

    {!outlined && (
      <Button
        onClick={handleClick}
        color="primary"
        variant="contained"
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <div style={{ display: 'flex', alignItems: 'center' }} className={styles.loader}>
            <CircularProgress color="primary" size={30} />
            {textForLoading && <div style={{ marginLeft: 10 }}>{textForLoading}</div>}
          </div>
        ) : (
          textContained
        )}
      </Button>
    )}
  </div>
);

BottomButtons.propTypes = {
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  textContained: PropTypes.string,
  handleClick: PropTypes.func,
  fixed: PropTypes.bool,
  disabled: PropTypes.bool,
  outlined: PropTypes.bool,
  newStyles: PropTypes.object,
  isLoading: PropTypes.bool,
  textForLoading: PropTypes.string,
  sticky: PropTypes.bool,
};

BottomButtons.defaultProps = {
  textContained: '',
  fixed: false,
  handleClick: () => {},
  disabled: false,
  outlined: false,
  newStyles: {},
  isLoading: false,
  textForLoading: '',
  sticky: false,
};

export default BottomButtons;
