import has from 'lodash/has';
import get from 'lodash/get';
import isDate from 'lodash/isDate';
import uniqBy from 'lodash/uniqBy';
import pluralize from 'pluralize';
import { add, endOfWeek, isThisWeek, format } from 'date-fns';
import { NETWORK_COLORS } from 'constants/colors';
import { getAccessToken } from './localStorage';
import clsx from 'clsx';

export const isServer = () => typeof window === 'undefined';
export const isClient = () => !isServer();

export const isInvalid = (name, { touched, errors }) => {
  const isTouched = has(touched, name);
  const hasError = has(errors, name);

  return isTouched && hasError;
};

export const getTokenFromResponse = response => {
  const authorization = get(response, 'headers.authorization', '');

  if (authorization.length > 0) {
    return authorization.split(' ')[1];
  }

  return null;
};

export const formatLargeNumber = number =>
  Intl.NumberFormat('en', { notation: 'compact' }).format(number);

export const formatPrice = (price, currency = 'USD') =>
  Intl.NumberFormat(currency === 'USD' ? 'en-US' : 'de-DE', { style: 'currency', currency })
    .format(price)
    .replace(/\D00$/, '');

export const isValid = (obj, exclude = []) =>
  !Object.keys(obj).some(
    k => (obj[k] === '' || obj[k] === null || obj[k] === undefined) && !exclude.includes(k)
  );

export const removeDuplicates = array =>
  array.filter((el, index) => index === array.findIndex(item => item.id === el.id));

export const normalizeInitialValues = values => {
  const normalized = {};

  Object.entries(values).forEach(([key, value]) => {
    let normalizedValue = value;

    if (normalizedValue === null || normalizedValue === undefined) {
      normalizedValue = '';
    }

    normalized[key] = normalizedValue;
  });

  return normalized;
};

export const getWebSocketUrl = () => {
  if (isServer()) return;

  return `${process.env.NEXT_PUBLIC_CABLE_URL}?token=${getAccessToken()}`;
};

export const snakeCase = str => str.replace(/\s+/g, '_').toLowerCase();

export const isValidDate = obj => isDate(obj) && obj.toString() !== 'Invalid Date';

export const initDate = value => {
  if (value === null) return null;
  if (isValidDate(value)) return value;

  const date = new Date(value);

  if (isValidDate(date)) return date;

  return null;
};

export const safeDateFormat = (date, formatStr, fallback = null) => {
  try {
    return format(date, formatStr);
  } catch {
    return fallback;
  }
};

export const getLiveTimeOption = option =>
  `${option.duration} ${pluralize(option.unit, option.duration)} ${
    option.default ? '(most requested)' : ''
  }`;

export const getMilestoneLiveTimeOption = (option, space = null) => {
  if (space?.offer) {
    return option.live_time_duration === 1 && option.live_time_unit === 'hour' ? 'Hourly' : 'Daily';
  }

  return `${option.live_time_duration} ${pluralize(
    option.live_time_unit,
    option.live_time_duration
  )} ${option.default ? '(most requested)' : ''}`;
};

export const getNetworkName = (networks, networkId) => {
  const network = networks.find(network => network.id === networkId);

  return network ? network.name : '';
};

export const identifications = [
  'Straight female',
  'Straight male',
  'Gay female',
  'Gay male',
  'Trans female',
  'Trans male',
  'Bisexual female',
  'Bisexual male',
  'Non-binary',
  'Prefer not to say',
];

export const crisp = () => {
  if (isClient()) return window.$crisp || [];
};

export const setCrispUser = user => {
  if (user.role === 'influencer') {
    crisp().push(['set', 'user:phone', [user.phone]]);

    if (user.contact_email) {
      crisp().push(['set', 'user:email', [user.contact_email]]);
    }
  } else {
    crisp().push(['set', 'user:email', [user.email]]);
  }

  crisp().push(['set', 'user:nickname', [user.display_name]]);
};

export const resetCrispSession = () => {
  crisp().push(['do', 'session:reset']);
};

export const openCrispChat = () => {
  crisp().push(['do', 'chat:open']);
};

export const getBadgeIcon = badge => {
  if (badge === 'Top Seller') {
    return <img width={14} src="/icons/crown/crown-white.svg" alt="Crown" />;
  } else if (badge === 'Top Buyer') {
    return <img width={14} src="/icons/crown/crown-white.svg" alt="Crown" />;
  } else if (badge === 'Newbie') {
    return <img src="/icons/newbie.svg" alt="Newbie" />;
  } else if (badge === 'Rising Star') {
    return <img src="/icons/profile/star-white.svg" alt="Rising Star" />;
  }

  return 'Unranked';
};

export const getPlayerBadgeIcon = badge => {
  if (badge === 'Top Seller') {
    return <img src="/icons/crown/crown-white.svg" alt="Top Seller" />;
  } else if (badge === 'Rising Star') {
    return <img src="/icons/profile/star-main.svg" alt="Rising Star" />;
  }
  return <img src="/icons/newbie.svg" alt="Newbie" />;
};

export const getGigOwner = (currentUser, gigUserId) => {
  if (
    currentUser.full_management_agency &&
    currentUser.currently_managing_profile &&
    currentUser.profile_id !== currentUser.primary_profile_id
  ) {
    // Acting as a specific profile that isn't the primary one
    return false;
  }

  return gigUserId === currentUser.id;
};

export const analyticsScreenshotNetworkIds = [1, 2, 3, 5, 6];

export const normalizeSpaceName = name => {
  switch (name) {
    case 'Event Attendance Hourly':
      return 'Event Attendance';
    case 'Event Attendance Daily':
      return 'Event Attendance';
    default:
      return name;
  }
};

export const normalizeNetworkName = name => {
  switch (name) {
    case 'Image & Likeness':
      return '';
    case 'Blog/Website':
      return '';
    default:
      return name;
  }
};

export const getMilestoneDates = (start, end) => {
  const startDate = new Date(start);
  const endDate = start === end ? endOfWeek(new Date(end)) : new Date(end);

  let label;
  let interval = false;

  if (new Date() > endDate) {
    label = 'Overdue';
    interval = true;
  } else if (isThisWeek(startDate, { weekStartsOn: 1 })) {
    label = 'This week';
    interval = true;
  } else if ((isThisWeek(add(startDate, { weeks: 1 })), { weekStartsOn: 1 })) {
    label = 'Next week';
    interval = true;
  } else {
    interval = false;
  }

  return { start: startDate, end: endDate, interval, label };
};

export const getMilestoneLabel = (milestone, gigOwner, styles) => {
  const map = {
    day: 'days',
    week: 'weeks',
    hour: 'hours',
    minute: 'minutes',
    month: 'months',
  };

  const takeDownDate = add(new Date(milestone.posted_at), {
    [map[milestone.live_time_unit]]: milestone.live_time_duration,
  });

  switch (milestone.status) {
    case 'awaiting_approval':
      return gigOwner ? (
        <span
          className={styles.background}
          style={{
            backgroundColor: '#5b00db',
            color: '#fff',
            fontFamily: 'Avenir Heavy',
          }}
        >
          Needs approval
        </span>
      ) : (
        <span className={clsx(styles.background, styles.lightPurple)} style={{ minWidth: 120 }}>
          Awaiting approval
        </span>
      );

    case 'approved':
      return (
        <span className={styles.background} style={{ backgroundColor: '#f2f4f9' }}>
          {gigOwner
            ? `Paid ${formatPrice(milestone.total_amount)}  ${format(
                new Date(milestone.approved_at),
                'MM/dd'
              )}`
            : `Received ${formatPrice(milestone.amount)}  ${format(
                new Date(milestone.approved_at),
                'MM/dd'
              )}`}
        </span>
      );

    case 'rejected':
      return (
        <span
          className={styles.background}
          style={{ backgroundColor: 'rgb(255, 75, 75, 0.2)', color: '#ff4b4b' }}
        >
          Rejected {format(new Date(milestone.rejected_at), 'MM/dd')}
        </span>
      );

    default:
      if (milestone.guaranteed_gains) {
        return <span className={clsx(styles.background, styles.lightPurple)}>In progress</span>;
      }

      if (new Date() > new Date(milestone.latest_start_time) && !milestone.expires_at) {
        return (
          <span
            className={styles.background}
            style={{ backgroundColor: 'rgb(255, 75, 75, 0.2)', color: '#ff4b4b' }}
          >
            Overdue
          </span>
        );
      }

      return gigOwner ? (
        <span
          className={styles.background}
          style={{ backgroundColor: '#f2f4f9', fontFamily: 'Avenir-Roman' }}
        >
          Expected: {format(new Date(milestone.delivery_by), 'MM/dd')}
        </span>
      ) : (
        <span className={clsx(styles.background, styles.lightPurple)}>
          Deliver by {format(new Date(milestone.delivery_by), 'MM/dd')}
        </span>
      );
  }
};

export const getNetworkColor = className => NETWORK_COLORS[className];

export const urlRegex = /^(http(s)?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/$\w \.@-]*)\??([\w-]+=[\w-]+&?)*$/i;

export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const sports = [
  'Baseball',
  'Basketball',
  'Beach volleyball',
  'Boxing',
  'Bowling',
  'Cross country running',
  'Esports',
  'Fencing',
  'Football',
  'Golf',
  'Gymnastics',
  'Hockey',
  'Lacrosse',
  'MMA',
  'Rowing',
  'Rugby',
  'Skateboarding',
  'Skiing',
  'Soccer',
  'Softball',
  'Swimming',
  'Tennis',
  'Track and field',
  'Triathlon',
  'Volleyball',
  'Water polo',
  'Wrestling',
];

export const followersMarks = [
  {
    value: 0,
    scaledValue: 1000,
    label: '1k',
  },
  {
    value: 25,
    scaledValue: 5000,
    label: '5k',
  },
  {
    value: 50,
    scaledValue: 10000,
    label: '10k',
  },
  {
    value: 75,
    scaledValue: 25000,
    label: '25k',
  },
  {
    value: 100,
    scaledValue: 50000,
    label: '50k',
  },
  {
    value: 125,
    scaledValue: 100000,
    label: '100k',
  },
  {
    value: 150,
    scaledValue: 250000,
    label: '250k',
  },
  {
    value: 175,
    scaledValue: 500000,
    label: '500k',
  },
  {
    value: 200,
    scaledValue: 1000000,
    label: '1M',
  },

  {
    value: 225,
    scaledValue: 10000000,
    label: '10M',
  },

  {
    value: 250,
    scaledValue: 100000000,
    label: '100M',
  },
];

export const fansMarks = [
  { value: 0, scaledValue: 0.01 },
  { value: 25, scaledValue: 0.05 },
  { value: 50, scaledValue: 0.1 },
  { value: 75, scaledValue: 0.2 },
  { value: 100, scaledValue: 0.3 },
  { value: 125, scaledValue: 0.4 },
  { value: 150, scaledValue: 0.5 },
  { value: 175, scaledValue: 1 },
  { value: 200, scaledValue: 1.5 },
  { value: 225, scaledValue: 2 },
  { value: 250, scaledValue: 3 },
  { value: 275, scaledValue: 4 },
  { value: 300, scaledValue: 5 },
  { value: 325, scaledValue: 10 },
  { value: 350, scaledValue: 20 },
  { value: 375, scaledValue: 50 },
  { value: 400, scaledValue: 100 },
];

export const USStates = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'District Of Columbia',
    code: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    code: 'FM',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virgin Islands',
    code: 'VI',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
];

export const getGigLabel = (gig, user) => {
  try {
    if (user.role === 'advertiser') {
      if (gig.status === 'pending') {
        if (gig.public) {
          return `${gig.applications_count} ${pluralize('new request', gig.applications_count)}`;
        }

        return 'Awaiting influencer response';
      } else if (gig.status === 'active') {
        if (gig.awaiting_approval_milestones_count > 0) {
          const count = gig.awaiting_approval_milestones_count;
          return `${count} ${pluralize('todo', count)} need${count <= 1 ? 's' : ''} approval`;
        }

        return `${gig.approved_milestones_count} of ${gig.milestones_count} delivered`;
      }
    } else if (user.role === 'influencer' && gig.status === 'active') {
      if (gig.active_milestones_count > 0 || gig.awaiting_approval_milestones_count === 0) {
        return `${gig.approved_milestones_count + gig.awaiting_approval_milestones_count} of ${
          gig.milestones_count
        } delivered`;
      }

      const count = gig.awaiting_approval_milestones_count;

      return `${count} ${pluralize('todo', count)} need${count <= 1 ? 's' : ''} approval`;
    }
  } catch {
    return '';
  }
};

export const getGigStatus = status => {
  switch (status) {
    case 'pending':
      return 'open';
    case 'active':
      return 'active';
    case 'cancelled':
      return 'cancelled';
    default:
      return 'completed';
  }
};

export const gigSortFn = (a, b, status, user, isBuyer = false) => {
  if (status !== 'active') return new Date(b.created_at) - new Date(a.created_at);

  if (user?.role === 'advertiser' || isBuyer) {
    return b.awaiting_approval_milestones_count - a.awaiting_approval_milestones_count;
  }

  const today = Date.now();
  const first = new Date(a.next_delivery_start_time);
  const second = new Date(b.next_delivery_start_time);

  return Math.abs(today - first) - Math.abs(today - second);
};

export const sortMilestones = milestones => {
  const toBePostedMilestones = milestones.filter(
    m => m.status === 'pending' && !m.posted_at && new Date() <= new Date(m.latest_start_time)
  );
  const overduedMilestones = milestones.filter(
    m => m.status === 'pending' && new Date() > new Date(m.latest_start_time) && !m.expires_at
  );
  const approvedMilestones = milestones.filter(m => m.status === 'approved');
  const rejectedMilestones = milestones.filter(m => m.status === 'rejected');

  const deliveredMilestones = milestones.filter(m => m.status === 'awaiting_approval');
  const postedMilestones = milestones.filter(
    m => m.posted_at && !['rejected', 'approved', 'awaiting_approval'].includes(m.status)
  );

  const draftMilestones = milestones.filter(m => m.status === 'draft');

  const sortedMilestones = [
    ...deliveredMilestones,
    ...postedMilestones,
    ...rejectedMilestones,
    ...overduedMilestones,
    ...toBePostedMilestones,
    ...approvedMilestones,
    ...draftMilestones,
  ];

  return uniqBy(sortedMilestones, 'id');
};

export const sortToDos = todos => {
  const sortedToDos = todos.map(todo => {
    let sort = 6;

    if (todo.status === 'rejected') {
      // Rejected has priority
      sort = 0;
    } else if (todo.posted_at && new Date() >= new Date(todo.expires_at)) {
      // Time to take down and upload delivery
      sort = 1;
    } else if (
      todo.status === 'pending' &&
      !todo.posted_at &&
      new Date() >= new Date(todo.earliest_start_time)
    ) {
      // Time to post proof screenshot
      sort = 2;
    } else if (todo.posted_at && new Date() <= new Date(todo.expires_at)) {
      // Live time hasn't expired yet. Waiting for takedown
      sort = 3;
    } else if (todo.status === 'awaiting_approval') {
      // Awaiting Approval
      sort = 4;
    } else if (todo.status === 'approved') {
      // Approved
      sort = 5;
    } else if (
      todo.status === 'pending' &&
      !todo.posted_at &&
      new Date() < new Date(todo.earliest_start_time)
    ) {
      // ToDo is not active yet
      sort = 10;
    }

    return { ...todo, sort };
  });

  return sortedToDos;
};

export const identifyFullStoryUser = user => {
  if (isServer()) return;

  if (typeof FS === 'undefined') return;

  FS.identify(user.id, {
    displayName: `${user.display_name} - ${user.role}`,
  });
};

export const setFullStoryVars = (page, data = {}) => {
  if (isServer()) return;

  if (typeof FS === 'undefined') return;

  FS.setVars('page', {
    pageName: page,
    ...data,
  });
};

export const normalizePrimaryInterest = interest => (interest === 'buy' ? 'As Buyer' : 'As Seller');

export const roundNumber = number => {
  if (!number) return '';

  return Math.round(number);
};

export const getFrequencyLabel = space => {
  const { name } = space;

  if (name.includes('Live')) {
    return 'How often should they occur?';
  } else if (name.includes('Event')) {
    return 'How often will they take place?';
  } else if (space.has_content) {
    return 'Delivered how often?';
  } else {
    return 'Posted how often?';
  }
};

export const shouldQuantityDisplay = space => {
  const { name } = space;
  const { network } = space;

  if (
    network.name === 'Image & Likeness' ||
    (network.name === 'TikTok' && name.includes('Sound'))
  ) {
    return false;
  } else return true;
};

export const getQuantityLabel = space => {
  const { name } = space;
  const { network } = space;

  if (name.includes('Post') && name !== 'Sponsor Post-Roll') {
    return 'How many posts?';
  } else if (name.includes('Live')) {
    return 'How many live streams?';
  } else if (name.includes('Event')) {
    return 'How many events?';
  } else if (
    (network.name === 'Image & Likeness' &&
      (name.includes('Video') ||
        name.includes('Photo') ||
        name.includes('Audio') ||
        name.includes('Review'))) ||
    (network.name === 'TikTok' && name.includes('Sound'))
  ) {
    return 'How many?';
  } else {
    return 'How many do you want?';
  }
};

export const getPayoutLabelName = status => {
  if (status === 'completed') {
    return 'Completed';
  } else if (['cancelled', 'pending', 'error', 'hold'].includes(status)) {
    return 'Cancelled';
  } else {
    return 'Pending';
  }
};

export const inventoryRedirectTo = (currentStep, router) => {
  switch (currentStep) {
    case 1:
      router.push('/inventory/networks');
      break;
    // In case we return this steps
    // case 2:
    //   router.push('/inventory/categories');
    //   break;
    // case 3:
    //   router.push('/inventory/audience');
    //   break;
    // case 4:
    //   router.push('/inventory/experience');
    //   break;
    case 2:
      router.push('/inventory/profile-info');
      break;
    case 3:
      router.push('/inventory/traits');
      break;
    case 4:
      router.push('/inventory/congratulations');
      break;

    default:
      return '';
  }
};

export const hasFollowers = influencerNetwork =>
  !['Podcast', 'Blog/Website', 'Image & Likeness'].includes(influencerNetwork.network.name);

export const countryToFlag = isoCode =>
  typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .substring(0, 2)
        .replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;

export const screenshotNetworkMapping = {
  Instagram: [
    { label: 'Profile', status_key: 'profile_screenshot_status', name: 'profile_screenshot' },
    // { label: 'Age / Gender', status_key: 'gender_screenshot_status', name: 'gender_screenshot' },
    // {
    //   label: 'Top Locations (Countries)',
    //   status_key: 'location_screenshot_status',
    //   name: 'location_screenshot',
    // },
  ],

  TikTok: [
    { label: 'Profile', status_key: 'profile_screenshot_status', name: 'profile_screenshot' },
    // { label: 'Gender', status_key: 'gender_screenshot_status', name: 'gender_screenshot' },
    // { label: 'Age', status_key: 'age_screenshot_status', name: 'age_screenshot' },
    // {
    //   label: 'Top Countries / Regions',
    //   status_key: 'location_screenshot_status',
    //   name: 'location_screenshot',
    // },
  ],

  Facebook: [
    {
      label: 'Your Page Link',
      status_key: 'username_screenshot_status',
      name: 'username_screenshot',
    },
    { label: 'Profile', status_key: 'profile_screenshot_status', name: 'profile_screenshot' },
    // { label: 'Analytics', status_key: 'analytics_screenshot_status', name: 'analytics_screenshot' },
  ],

  Twitter: [
    { label: 'Profile', status_key: 'profile_screenshot_status', name: 'profile_screenshot' },
  ],

  YouTube: [
    { label: 'Profile', status_key: 'profile_screenshot_status', name: 'profile_screenshot' },
    // { label: 'Age / Gender', status_key: 'gender_screenshot_status', name: 'gender_screenshot' },
    // { label: 'Location', status_key: 'location_screenshot_status', name: 'location_screenshot' },
  ],

  OnlyFans: [
    { label: 'Profile', status_key: 'profile_screenshot_status', name: 'profile_screenshot' },
    {
      label: 'Analytics (Top %)',
      status_key: 'analytics_screenshot_status',
      name: 'analytics_screenshot',
    },
  ],

  LoyalFans: [
    { label: 'Profile', status_key: 'profile_screenshot_status', name: 'profile_screenshot' },
  ],

  'Blog/Website': [],
  Podcast: [],
  'Image & Likeness': [],
};

export const screenshotsMapping = {
  TikTok: {
    url: 'https://gigsocial.crisp.help/en-us/article/tiktok-screenshots-12sh6k/?bust=1687473065155',
  },

  Instagram: {
    url:
      'https://gigsocial.crisp.help/en-us/article/instagram-screenshots-a9s1tf/?bust=1687475683966',
  },

  YouTube: {
    url:
      'https://gigsocial.crisp.help/en-us/article/youtube-screenshots-1xlbxmh/?bust=1687475519214',
  },

  Twitter: {
    url:
      'https://gigsocial.crisp.help/en-us/article/twitter-screenshots-1xbfw3k/?bust=1687475578563',
  },

  Facebook: {
    url:
      'https://gigsocial.crisp.help/en-us/article/facebook-screenshots-moj6ym/?bust=1687475445756',
  },

  OnlyFans: {
    url:
      'https://gigsocial.crisp.help/en-us/article/onlyfans-screenshots-ec95vr/?bust=1687475959240',
  },

  LoyalFans: {
    url:
      'https://gigsocial.crisp.help/en-us/article/loyalfans-screenshots-1dvu1tq/?bust=1687475629166',
  },
};

export const previewUrl = path => window.location.host.replace('www.', '') + path;

export const getButtonUrl = (button, username) => {
  if (!button) return '';

  if (button.public_profile) return `${window.location.origin}/u/${username}`;
  if (button.wishlist_profile) return `${window.location.origin}/u/${username}/wishlist`;
  if (button.fan_profile) return `${window.location.origin}/u/${username}?fan=1`;

  return button.url;
};

export const getOnlyFansC4CreatorId = influencerNetworks => {
  const onlyFansNetwork = influencerNetworks.find(
    influencerNetwork => influencerNetwork.network_id === 6
  );

  return onlyFansNetwork?.c4_creator_id;
};

export const formatGigPrice = (gig, price) => {
  if (gig.special_offer_ad) {
    return <span style={{ color: '#5b00db' }}>Special Offer</span>;
  }

  return <span>{formatPrice(price)}</span>;
};

export const ONLYFANS_NETWORK_ID = 6;
export const IMAGE_AND_LIKENESS_NETWORK_NAME = 'Image & Likeness';
