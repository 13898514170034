import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

const useIsIframe = () => {
  const router = useRouter();
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsIframe(window.self !== window.top);
    }
  }, [router.pathname]);

  return isIframe;
};

export default useIsIframe;
